import React from "react";
import CarouselEngine from "./carousel_engine";
import CarouselItem from "./carousel_item";

import logo_1 from "../../images/partners_logor/1_monitor.jpg";
import logo_2 from "../../images/partners_logor/2_jeeves.jpg";
import logo_3 from "../../images/partners_logor/3_levilo.jpg";
import logo_4 from "../../images/partners_logor/4_edison.jpg";
import logo_5 from "../../images/partners_logor/5_swinx.jpg";
import logo_6 from "../../images/partners_logor/6_rexor.jpg";
import logo_7 from "../../images/partners_logor/7_docup.jpg";
import kr_system from "../../images/partners_logor/kr_system_logo.jpg";
import logo_8 from "../../images/partners_logor/8_consili.jpg";
import logo_9 from "../../images/partners_logor/9_gc.jpg";
import logo_10 from "../../images/partners_logor/10_zoezi.jpg";
import logo_11 from "../../images/partners_logor/11_next.jpg";
import logo_12 from "../../images/partners_logor/12_projektportalen.jpg";
import logo_13 from "../../images/partners_logor/13_trinax.jpg";
import logo_14 from "../../images/partners_logor/proclient.jpg";

const logos = [logo_1, logo_2, logo_3, logo_4, logo_5, logo_6, logo_7, kr_system, logo_8, logo_9, logo_10, logo_11, logo_12, logo_13, logo_14];

function MyCarousel(){
  return (
    <CarouselEngine >
      {logos.map(i => 
        <CarouselItem 
          image_url={i}
        />
      )}
    </CarouselEngine>
  );
}
export default MyCarousel;