import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./carousel.css";


const CarouselEngine = ({ children, responsive_items }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [paused, setPaused] = useState(false);
    const [responsive, setResponsive] = useState(100/5);
  
    const updateIndex = (newIndex) => {
      if (newIndex < 0) {
        newIndex = React.Children.count(children) - 1;
      } else if (newIndex >= React.Children.count(children)) {
        newIndex = 0;
      }
  
      setActiveIndex(newIndex);
    };
  
    const handle_resize = (current_size) => {
      if(current_size > 0 && current_size <= 768 ){
        setResponsive(100);
      }
      else  if(current_size > 768 && current_size <= 1024 ){
        setResponsive(100/3);
      } 
      else  if(current_size > 1024 && current_size <= 1280 ){
        setResponsive(100/4);
      }
      else  if(current_size > 1280 && current_size <= 1536 ){
        setResponsive(100/5);
      }
      else  if(current_size > 1536){
        setResponsive(100/6);
      }
    }

    useEffect(() => {
      if (typeof window !== `undefined`) {
        handle_resize(window.innerWidth);
      }
      
      const interval = setInterval(() => {
        if (!paused) {
          updateIndex(activeIndex + 1);
        }
      }, 3000);
  
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    });
  
    const handlers = useSwipeable({
      onSwipedLeft: () => updateIndex(activeIndex + 1),
      onSwipedRight: () => updateIndex(activeIndex - 1)
    });
  
    return (
      <div className="carousel"  role="presentation" {...handlers} onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}>
        <div className="carousel_items">
            <button className="arrow_btn" onClick={() => {updateIndex(activeIndex - 1);}}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="btn_svg" viewBox="0 0 16 16">
                    <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                </svg>
            </button>
    
            <div className="carousel_main" style={{transform: `translateX(-${activeIndex * responsive}%)` }}>
                {React.Children.map(children, (child, index) => {
                return React.cloneElement(child, { width: `${responsive}%` });
                })}
            </div>
    
            <button className="arrow_btn" onClick={() => { updateIndex(activeIndex + 1); }}>
                < svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="btn_svg" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                </svg>
            </button>
        </div>


        <div className="carousel_dots">
            {React.Children.map(children, (child, index) => {
                return (
                <button onClick={() => {updateIndex(index); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="dots_svg" viewBox="0 0 16 16">
                    {index === activeIndex 
                    ? 
                        <circle cx="8" cy="8" r="8"/>
                    :
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    }
                    </svg>
                </button>
                );
            })}
        </div>
      </div>
    );
};
export default CarouselEngine;