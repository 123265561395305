import React from "react";
import { Link } from "gatsby";
import { Slide } from "react-reveal";

import kontakta_oss_img from "../images/kontakta-oss.svg"

function SupportCard(props){
    return(
        <Slide bottom>
            <Link to="/om-oss">
            <div className="h-full p-6 flex flex-col items-center justify-center bg-gradient-to-b from-white to-gray-100 rounded-lg  hover:shadow-lg transition-all transform hover:scale-105">
                <div className="w-full flex items-center justify-center">
                <img className="w-32" src={kontakta_oss_img} alt="" />
                </div>
                <h2 className="mt-4 text-center font-medium text-2xl">{props.name}</h2>
                <p className="mt-4 text-center text-gray-600"><div className=" bg-white shadow-md rounded-full px-6 py-1">{props.position}</div></p>
                <p className="mt-4 text-center text-blue-custome">{props.phone}</p>
            </div>
            </Link>
        </Slide>
    );
}
export default SupportCard;