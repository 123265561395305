import React from "react";
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import { Fade, Slide } from 'react-reveal';
import favicon from '../images/favicon.ico';
import Wave from 'react-wavify';

// components
import Testimonial from "../components/testimonial";
import LatestNews from "../components/news/news";
import MyCarousel from "../components/carousel/carousel";
import SupportCard from "../components/supportCard";

//images
import globalconnect_img from "../images/partners_logor/globalconnect.png";
import koenigsegg_img from "../images/partners_logor/koenigsegg.png";
import monitor_img from "../images/partners_logor/1_monitor.jpg";

import ta_emot_icon from "../images/icons/ta-emot.svg";
import skicka_icon from "../images/icons/skicka.svg";
import edi_icon from "../images/icons/edi.svg";
import p27_icon from "../images/icons/p27.svg";

function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Crediflow AB</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="description" content="Crediflow digitaliserar och automatiserar inkommande och utgående flöden av fakturor och elektroniska dokument." />
        <meta name="keywords" content="inkommande fakturor || utgående fakturor || automatisera elektroniska dokument" />
        <meta property="og:title" content="Crediflow AB" />
        <meta property="og:tyope" content="website" />
        <meta property="og:discription" content="Crediflow digitaliserar och automatiserar inkommande och utgående flöden av fakturor och elektroniska dokument." />
        <meta property="og:image" content="" />
        <meta property="og:locale" content="sv_SE" />
        <meta property="og:url" content="http://www.crediflow.se/" />
        <link rel="cannonical" href="http://www.crediflow.se/" />
      </Helmet>

      {/***********  Hero Section - END  **********/}
      <section className="bg-gray-100 pb-24">
        <div className="w-screen bg-hero-banner bg-top bg-no-repeat bg-cover">
          <div className="container mx-auto px-4 xl:px-24">
            <div className="py-24">
              <h1 className="w-full lg:w-5/6 mx-auto text-center  text-4xl md:text-5xl  font-bold md:leading-snug  text-white filter drop-shadow-sm">Vi digitaliserar och automatiserar affärsprocesser!</h1>
              <p className="md:w-2/3 mx-auto text-center mt-10 lg:text-xl text-white">
                Våra tjänster används idag i flertalet ekonomi- och affärssystem på den 
                svenska och europeiska marknaden för att digitalisera och automatisera 
                inkommande och utgående flöden av fakturor och elektroniska dokument. 
                Tillsammans skapar vi förutsättningar för företag att på ett 
                enkelt sätt kunna digitalisera sina processer och arbeta 
                enklare och smartare.
              </p>
            </div>
          </div>
          <div>
            <Wave 
              className="-mb-2"
              fill='#F3F4F6'
              paused={false}
              options={{
              height: 20,
              amplitude: 50,
              speed: 0.15,
              points: 3
              }}
            />
          </div>
        </div>
        <div className="container mx-auto px-4 xl:px-24 -mt-36">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/leverantorsfakturor">
                <div className="h-full w-full px-6 py-8 ">
                  <div className="w-20 mx-auto">
                    <img className="" src={ta_emot_icon} alt="Leverantörsfakturor"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">Leverantörsfakturor</h2>
                  <p className="mt-4 text-gray-500">
                    En kanal in för alla dina fakturor oavsett om 
                    det är e-faktura, papper eller pdf. Flexibelt 
                    att välja vilka delar du vill göra själv eller outsourca.
                  </p>
                </div>
              </Link>
            </div>

            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/kundfakturor"  >
                <div className="h-full w-full px-6 py-8 ">
                  <div className="w-20 mx-auto">
                    <img className="" src={skicka_icon} alt="Kundfakturor"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">Kundfakturor</h2>
                  <p className="mt-4 text-gray-500">
                    Oavsett hur din kund önskar ta emot fakturor från dig så har vi lösningen.
                    E-faktura, e-post, print eller digitala boxar som t ex Kivra.
                  </p>
                </div>
              </Link>
            </div>

            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/edi">
                <div className="h-full w-full px-6 py-8">
                  <div className="w-20 mx-auto">
                    <img className="" src={edi_icon} alt="EDI"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">EDI</h2>
                  <p className="mt-4 text-gray-500">
                    Vi erbjuder en helhetslösning för 
                    EDI oavsett om ni vill skicka eller ta emot EDI-meddelanden.
                  </p>
                </div>
              </Link>
            </div>
        
            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/sdk">
                <div className="h-full w-full px-6 py-8 ">
                  <div className="w-20 mx-auto">
                    <img className="" src={p27_icon} alt="SDK (Säker digital kommunikation)"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">SDK</h2>
                  <p className="mt-4 text-gray-500">
                    SDK är en digital plattform för utbyte 
                    av känslig och ostrukturerad information 
                    på ett säkert och krypterat sätt.
                  </p>
                </div>
              </Link>
            </div>

          </div>
        </div>
      </section>
      {/***********  Hero Section - END  **********/}



      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>



      {/***********  Carousel Section - START  **********/}
      <section className="w-screen bg-gray-100 pt-20 pb-24">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Partners</h1>
          <p className="mt-4 text-center text-gray-600">Gör som andra, upplev helheten!</p>
          <div className="w-full mt-14">
            <MyCarousel />
          </div>
        </div>
      </section>
      {/***********  Carousel Section - END  **********/}



      
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>




      {/***********  About Section - START  **********/}
      <section className="bg-gray-100 py-24">
        <div className="container mx-auto px-4 xl:px-24">
          <div className="grid xl:grid-cols-2 gap-32 lg:gap-20">
            <Slide left>
              <div className="w-full">
                <h2 className="title-font font-semibold text-3xl text-gray-900">Kan ett modernt företag ha över 25 års erfarenhet?</h2>
                <p className="leading-relaxed mt-6 text-gray-600">
                  Svaret är enkelt &#8211; JA! Vi har över 25 års erfarenhet och 
                  har med våra leveranser till offentlig myndighet så 
                  väl som till det lilla bolaget en enorm erfarenhet av 
                  den marknaden vi verkar på.
                  <br/><br/>
                  Vår koncern omsätter idag närmare 100 miljoner per år 
                  och har Tripple AAA rating sedan flera år tillbaka, 
                  vilket innebär att du får en finansiellt stark leverantör 
                  som också älskar teknik. Våra kunder får alltså ta del 
                  av den modernaste teknologin som finns på marknaden 
                  så som AI, machine learning RPA etc.
                  <br/><br/>
                  Vi vill göra din resa inom digitalisering enkel, låt 
                  oss ta med dig in i framtiden. Visste du att vi 
                  dessutom sparar pengar åt våra kunder och partners?
                  <br/><br/>
                  Se nedan vad några av våra kunder säger om oss, och 
                  kontakta oss sen för en demo eller ett samtal om dina behov.
                </p>            
              </div>
            </Slide>
            <Slide right>
                <div className="w-full mx-auto flex items-start justify-center bg-shape bg-no-repeat bg-contain bg-top">
                  <div className="w-full max-w-sm mt-14 lg:mt-0 flex justify-center items-center">
                    <div className="w-10/12 transform rotate-1 sm:rotate-3 ">
                      <div className="custom_3d_img transform -skew-x-2  sm:-skew-x-6 pr-3 pb-3 bg-gradient-to-br from-blue-400 to-blue-900" >
                        <div className="bg-gradient-to-tl from-gray-400 to-blue-800 rounded-3xl pb-3 pt-2 pl-1 pr-2">
                          <div className="bg-white rounded-3xl overflow-hidden" style={{height: "550px"}}>
                            <div className="h-full w-full flex flex-col justify-between">
                              <iframe src="https://bot.leadoo.com/bot/inpage.html?code=nSEzKskQ" style={{border: "none", width: "100%", height: "380px"}} title="crediflow ai"/>
                              <div className="w-full h-40 m-1.5 bg-no-repeat bg-keyboard bg-contain bg-bottom border-t border-gray-200"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </Slide>
          </div>
        </div>
      </section>
      {/***********  About Section - END  **********/}





      {/***********  Review Section - START  **********/}
      <section className="bg-gray-100 py-24">
        <div className="container mx-auto px-4 xl:px-24">
          <Slide bottom>
            <div className="grid lg:grid-cols-3 gap-8">
              <Testimonial company="Global Connect" logo={globalconnect_img}>
                <div className="h-full lg:h-52 xl:h-48 2xl:h-32">
                    <p className="mt-2 text-gray-600">
                      Crediflow är otroligt lyhörda och deras 
                      lösning CrossState eVoyce har underlättat 
                      vårt arbete massor!
                    </p>
                </div>

              </Testimonial>

              <Testimonial company="Koenigsegg" logo={koenigsegg_img}>
                <div className="h-full lg:h-52 xl:h-48 2xl:h-32">
                    <p className="mt-2 text-gray-600">
                      Koenigsegg strävar efter att nå den ultimata 
                      prestandan. Smidig och snabb fakturahantering 
                      genom Crediflow är en kugge i hjulet, så vi 
                      kan nå våra mål
                    </p>
                </div>
              </Testimonial>

              <Testimonial company="MONITOR" logo={monitor_img}>
                <div className="h-full lg:h-52 xl:h-48 2xl:h-32">
                    <p className="mt-2 text-gray-600">
                      Samarbetet med Crediflow, en lyhörd och 
                      engagerad leverantör, ger hög kvalitet 
                      i vår slutprodukt och är en viktig 
                      del för att upprätthålla ett affärssystem 
                      i världsklass.
                    </p>
                </div>

              </Testimonial>
            </div>
          </Slide>
        </div>
      </section>
      {/***********  Review Section - END  **********/}
      


      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>



      {/***********  Stats Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24 ">
        <div className="container mx-auto xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Du kommer inte vara ensam!</h1>
          <p className="px-4 mt-4 w-full md:w-1/2 xl:w-1/3 mx-auto text-center text-gray-600">Vi hanterar idag en stor del av alla fakturor som skickas och tas emot i Norden och andra länder!</p>
          <div className="mt-14 bg-world bg-cover bg-no-repeat bg-top">
            <Fade>
              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12 lg:gap-20 pt-12">
                <div className="w-44 h-44 md:w-40 md:h-40 lg:w-44 lg:h-44 mx-auto rounded-full pt-4 flex flex-col items-center justify-center shadow-xl" style={{background: "rgba(265, 265, 265, 0.75)"}}>
                  <h1 className="text-3xl font-bold text-center text-gray-800">200 000</h1>
                  <p className="text-gray-600">Per Dag</p>
                </div>

                <div className="w-44 h-44 mx-auto rounded-full pt-4 flex flex-col items-center justify-center shadow-xl" style={{background: "rgba(265, 265, 265, 0.75)"}}>
                  <h1 className="text-3xl font-bold text-center text-gray-800">8 500 000</h1>
                  <p className="text-gray-600">Per månad</p>
                </div>

                <div className="w-44 h-44 mx-auto rounded-full pt-4 flex flex-col items-center justify-center shadow-xl" style={{background: "rgba(265, 265, 265, 0.75)"}}>
                  <h1 className="text-3xl font-bold text-center text-gray-800">45 000 000</h1>
                  <p className="text-gray-600">Per År</p>
                </div>

                <div className="w-44 h-44 mx-auto rounded-full pt-4 flex flex-col items-center justify-center shadow-xl" style={{background: "rgba(265, 265, 265, 0.75)"}}>
                  <h1 className="text-3xl font-bold text-center text-gray-800">23</h1>
                  <p className="text-gray-600">Länder</p>
                </div>
              </div>
            </Fade>
            <div className="bg-gradient-to-b from-transparent via-transparent to-gray-100 md:h-32"></div>
          </div>
        </div>
      </section>
      {/***********  Stats Section - END  **********/}



      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>



      {/***********  News Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24 ">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Nyheter</h1>
          <p className="mt-4 text-center text-gray-600">Senaste nyheterna från Crediflow</p>
          <div className="mt-14">
            <LatestNews />
          </div>
          <div className="w-full mt-14 flex justify-center items-center">
            <Link to="/nyheter" className="px-6 py-2 rounded-full text-white bg-blue-custome shadow-md hover:opacity-90">Nyfiken på fler nyheter?</Link>
          </div>
        </div>
      </section>
      {/***********  News Section - END  **********/}
      

      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>


      {/***********  Support Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Kundcenter</h1>
          <p className="mt-4 text-center text-gray-600">Det är oss du kommer möta när du väljer Crediflow!</p>
          <div className="mt-14 grid  md:grid-cols-2 xl:grid-cols-3 gap-8">

            <SupportCard
              name="Kjell Bergendahl"
              position="Support Team Stockholm"
              phone=""                        
            />
            <SupportCard
                name="Ann-Kristine Pettersson"
                position="Front Office Stockholm &amp; Helsingborg"
                phone="08-578 604 00"                        
            />
            <SupportCard
                name="Nicklas Nylén"
                position="Support Team Stockholm"
                phone=""                        
            />
            <SupportCard
                name="Adam Fins"
                position="Support Team Stockholm"
                phone=""                        
            />
            <SupportCard
                name="Felix Oxing"
                position="Support Team Helsingborg"
                phone=""                        
            />
            <SupportCard
                name="Jonas Schrewelius"
                position="Support Team Helsingborg"
                phone=""                        
            />
          </div>
          <div className="w-full mt-14 flex justify-center items-center">
            <Link to="/om-oss" className="px-6 py-2 rounded-full text-white bg-blue-custome shadow-md hover:opacity-90">Träffa fler av oss!</Link>
          </div>
        </div>
      </section>
      {/***********  Support Section - END  **********/}



      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>



      {/***********  Map Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Våra kontor</h1>
          <p className="mt-4 text-center text-gray-600">Vi finns närmare än du tror!</p>
          <div className="grid lg:grid-cols-2 gap-8 mt-14">
            <div>
              <iframe 
                className="w-full h-80 rounded-lg shadow-lg"
                title="google_maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1664.8589882283513!2d18.092931875594783!3d59.44172626589987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9bee7fbb256b%3A0x97ee9d120068107b!2sOptosweden%20AB!5e0!3m2!1sen!2sus!4v1604924344158!5m2!1sen!2sus" 
              />
              <h3 className="text-xl text-center mt-10 font-semibold ">Stockholm</h3>
              <p className="mt-1 text-gray-600 text-center">Fogdevägen 4B<br/>183 64 Täby, Stockholm</p>
            </div>
     
            <div>
              <iframe 
                className="w-full h-80 rounded-lg shadow-lg"
                title="google_maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2228.3167910685897!2d12.693077015942004!3d56.04781748063327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46523235de5cf011%3A0x88c55dc9cd6a60e4!2sNorra%20Storgatan%208%2C%20252%2020%20Helsingborg!5e0!3m2!1ssv!2sse!4v1612899702765!5m2!1ssv!2sse" 
              />
              <h3 className="text-xl text-center mt-10 font-semibold ">Helsingborg</h3>
              <p className="mt-1 text-gray-600 text-center">Norra Storgatan 8<br/>252 20 Helsingborg, Skåne</p>
            </div>
            
          </div>
        </div>
      </section>
      {/***********  Map Section - END  **********/}


      
    </Layout>
  );
}

export default Home;